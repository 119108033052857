<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="供应商">
        <base-select info="Supplier" v-model="filter.supplierId"></base-select>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.storeId"></base-select>
      </el-form-item>
      <el-form-item label="显示所有">
        <el-switch v-model="filter.showAll"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
    >
      <el-table-column label="供应商" prop="supplierName" show-overflow-tooltip sortable></el-table-column>
      <el-table-column label="期初" prop="qc" show-overflow-tooltip sortable>
        <template slot-scope="scope">{{scope.row.qc|thousands}}</template>
      </el-table-column>
      <el-table-column label="本期预付" prop="repay" show-overflow-tooltip sortable>
        <template slot-scope="scope">{{scope.row.repay|thousands}}</template>
      </el-table-column>
      <el-table-column label="本期应付" prop="should" show-overflow-tooltip sortable>
        <template slot-scope="scope">{{scope.row.should|thousands}}</template>
      </el-table-column>
      <el-table-column label="本期结算" prop="pay" show-overflow-tooltip sortable>
        <template slot-scope="scope">{{scope.row.pay|thousands}}</template>
      </el-table-column>
      <el-table-column label="结存" prop="balance" show-overflow-tooltip sortable>
        <template slot-scope="scope">{{scope.row.balance|thousands}}</template>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import setName from "@/common/setName";
import toExcel from "@/common/toExcel";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
    ProjectSelect
  },
  data() {
    return {
      filter: {
        begin: this.$moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
        showAll: false
      },
      data: []
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("PaymentReport/Summary", params)
        .then(r => {
          r.forEach(item => {
            setName("Supplier", item, "supplierId", "supplierName");
            item.balance = this.amountFormat(
              item.qc + item.should - item.pay - item.repay
            );
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    excel() {
      let list = [];
      this.data.forEach(item => {
        list.push({
          supplierName: item.supplierName,
          qc: this.amountFormat(item.qc),
          repay: this.amountFormat(item.repay),
          should: this.amountFormat(item.should),
          pay: this.amountFormat(item.pay),
          balance: this.amountFormat(item.balance)
        });
      });
      let header = [
        "供应商",
        "期初",
        "本期预付",
        "本期应付",
        "本期结算",
        "结存"
      ];
      let column = ["supplierName", "qc", "repay", "should", "pay", "balance"];
      toExcel(header, column, list, "应付账款汇总");
    },
    dblclickTable(row) {
      this.$store.commit("setFilterCache", {
        name: "PaymentDetailReport",
        filter: {
          begin: this.filter.begin,
          end: this.filter.end,
          showAll: this.filter.showAll,
          supplierId: row.supplierId,
          projectId: this.filter.projectId,
          storeId: this.filter.storeId
        }
      });
      this.$router.push({
        name: "PaymentDetailReport"
      });
    }
  }
};
</script>

<style>
</style>